import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "push-events-to-google-analytics-4"
    }}>{`Push events to Google Analytics 4`}</h2>
    <h3 {...{
      "id": "smilee-can-push-the-following-events-to-google-analytics"
    }}>{`Smilee can push the following events to Google Analytics`}</h3>
    <p><strong parentName="p">{`agentJoined`}</strong>{` `}<br />{`
`}<strong parentName="p">{`event:`}</strong>{` Agent has joined the chat `}<br />{`
`}<strong parentName="p">{`label format:`}</strong>{` `}<inlineCode parentName="p">{`Session ID`}</inlineCode>{`:`}<inlineCode parentName="p">{`agent name`}</inlineCode></p>
    <p><strong parentName="p">{`autoPopUpTriggered`}</strong>{` `}<br />{`
`}<strong parentName="p">{`event:`}</strong>{` Chat autopopup event has been triggered `}<br />{`
`}<strong parentName="p">{`label format:`}</strong>{` `}<inlineCode parentName="p">{`Session ID`}</inlineCode></p>
    <p><strong parentName="p">{`sessionRated`}</strong>{` `}<br />{`
`}<strong parentName="p">{`event:`}</strong>{` Session has been rated `}<br />{`
`}<strong parentName="p">{`label format:`}</strong>{` `}<inlineCode parentName="p">{`Session ID`}</inlineCode>{`:`}<inlineCode parentName="p">{`agent name`}</inlineCode></p>
    <p><strong parentName="p">{`contactFormSent`}</strong>{` `}<br />{`
`}<strong parentName="p">{`event:`}</strong>{` Contact form was sent `}<br />{`
`}<strong parentName="p">{`label format:`}</strong>{` `}<inlineCode parentName="p">{`Session ID`}</inlineCode></p>
    <p><strong parentName="p">{`cookiesAccepted`}</strong>{` `}<br />{`
`}<strong parentName="p">{`event:`}</strong>{` Smilee cookie consent accepted `}<br />{`
`}<strong parentName="p">{`label format:`}</strong>{` `}<inlineCode parentName="p">{`Session ID`}</inlineCode></p>
    <p><strong parentName="p">{`cookiesDeclined`}</strong>{` `}<br />{`
`}<strong parentName="p">{`event:`}</strong>{` Smilee cookie consent declined `}<br />{`
`}<strong parentName="p">{`label format:`}</strong>{` `}<inlineCode parentName="p">{`Session ID`}</inlineCode></p>
    <p><strong parentName="p">{`tabClosed`}</strong>{` `}<br />{`
`}<strong parentName="p">{`event:`}</strong>{` Smilee chat UI closed `}<br />{`
`}<strong parentName="p">{`label format:`}</strong>{` `}<inlineCode parentName="p">{`Session ID`}</inlineCode></p>
    <p><strong parentName="p">{`tabClicked`}</strong>{` `}<br />{`
`}<strong parentName="p">{`event:`}</strong>{` Smilee chat UI opened `}<br />{`
`}<strong parentName="p">{`label format:`}</strong>{` `}<inlineCode parentName="p">{`Session ID`}</inlineCode></p>
    <p><strong parentName="p">{`videoCall`}</strong>{` `}<br />{`
`}<strong parentName="p">{`event:`}</strong>{` Smilee video call started `}<br />{`
`}<strong parentName="p">{`label format:`}</strong>{` `}<inlineCode parentName="p">{`Session ID`}</inlineCode></p>
    <p><strong parentName="p">{`cobStarted`}</strong>{` `}<br />{`
`}<strong parentName="p">{`event:`}</strong>{` Smilee cobrowsing started `}<br />{`
`}<strong parentName="p">{`label format:`}</strong>{` `}<inlineCode parentName="p">{`Session ID`}</inlineCode></p>
    <p><strong parentName="p">{`assistantLoaded`}</strong>{` `}<br />{`
`}<strong parentName="p">{`event:`}</strong>{` Smilee assistant UI loaded `}<br />{`
`}<strong parentName="p">{`label format:`}</strong>{` `}<inlineCode parentName="p">{`Session ID`}</inlineCode></p>
    <p><strong parentName="p">{`assistantClick`}</strong>{` `}<br />{`
`}<strong parentName="p">{`event:`}</strong>{` Assistant button has been clicked `}<br />{`
`}<strong parentName="p">{`label format:`}</strong>{` `}<inlineCode parentName="p">{`Session ID`}</inlineCode>{`:`}<inlineCode parentName="p">{`Type of button`}</inlineCode>{`:`}<inlineCode parentName="p">{`Text of button`}</inlineCode></p>
    <p><strong parentName="p">{`assistantClick`}</strong>{` `}<br />{`
`}<strong parentName="p">{`event:`}</strong>{` Enduser has clicked the back button of assistant `}<br />{`
`}<strong parentName="p">{`label format:`}</strong>{` `}<inlineCode parentName="p">{`Session ID`}</inlineCode>{`:back`}</p>
    <h3 {...{
      "id": "add-events-to-script"
    }}>{`Add events to script`}</h3>
    <p>{`First step is to add `}<inlineCode parentName="p">{`eventReceiver`}</inlineCode>{` to script running the chat`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`eventReceiver: function(props) {
  dataLayer.push(Object.assign({}, {'event': 'ChatEvent'}, props));
}
`}</code></pre>
    <p>{`Full example of script below`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<!-- Smilee service script start -->
<script type="text/javascript" charset="UTF-8" async>
  (function () {
    var x = document.createElement('script');
    x.src = 'https://saas.smilee.io/assets/javascripts/cobrowse.js';
    x.type = 'text/javascript';
    x.async = 'true';
    x.onload = x.onreadystatechange = function () {
      var rs = this.readyState;
      if (rs && rs != 'complete' && rs != 'loaded') return;
      try {
        Cobrowse.create({
          apiKey: "LQt/yocAfcWRAt...",
          eventReceiver: function(props) {
            dataLayer.push(Object.assign({}, {'event': 'ChatEvent'}, props));
          }
        });
      } catch (e) {
        console.error('Failed loading Smilee script');
      }
    };
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(x, s);
  })();
</script>
<!-- Smilee service script end -->
`}</code></pre>
    <h3 {...{
      "id": "add-a-trigger-for-smilee-events"
    }}>{`Add a trigger for Smilee events`}</h3>
    <p><img alt="Triggers menu" src={require("../public/images/triggers-start.png")} />{`
Insert a new Trigger with a name `}<strong parentName="p">{`Smilee events`}</strong></p>
    <p><img alt="Chat event trigger" src={require("../public/images/gtm-smilee-trigger-allCustomEvents.png")} /></p>
    <p>{`Give it type `}<strong parentName="p">{`Custom Event`}</strong>{` `}<br />{`
Event name: `}<strong parentName="p">{`ChatEvent`}</strong>{` `}<br />{`
Trigger on `}<strong parentName="p">{`All Custom Events`}</strong>{` `}<br />{`
Click Save `}<br /></p>
    <h3 {...{
      "id": "add-variables"
    }}>{`Add variables`}</h3>
    <p><img alt="Chat action variable" src={require("../public/images/gtm-smilee-variable-chatAction.png")} />{`
Go to Variables-section and add new User-Defined Variable `}<br />{`
Select type Data Layer Variable `}<br />{`
Give name `}<strong parentName="p">{`smileechat event action`}</strong>{` `}<br />{`
Data Layer variable name `}<strong parentName="p">{`chatAction`}</strong>{` `}<br />{`
Click Save `}<br /></p>
    <h3 {...{
      "id": "configure-ga4-tag"
    }}>{`Configure GA4 tag`}</h3>
    <p>{`In Tags-section click to add a new tag `}<br />{`
Select Google Analytics: GA4 Configuration `}<br />{`
Set your Google Analytics 4 Measurement ID `}<br />{`
Add a new trigger and select All Pages-trigger `}<br />{`
Click Save `}<br /></p>
    <p><img alt="Google Analytics 4 configuration tag" src={require("../public/images/gtm-ga4-config.png")} /></p>
    <h3 {...{
      "id": "create-tag-for-pushing-to-google-analytics-4"
    }}>{`Create tag for pushing to Google Analytics 4`}</h3>
    <p>{`Tag type: Google Analytics: GA4 Event `}<br />{`
Select GA4 configuration tag or set GA4 Tracking ID manually `}<br />{`
Set `}<strong parentName="p">{`smileechat event action`}</strong>{`-variable as Event Name `}<br />{`
Set `}<strong parentName="p">{`smileechat event action`}</strong>{`-variable also as Parameter Name and as Value `}<br />{`
Set `}<strong parentName="p">{`Smilee events`}</strong>{` as the trigger for this tag `}<br />{`
Click Save `}<br /></p>
    <p><img alt="Push to Google Analytics" src={require("../public/images/gtm-ga4-smilee-tag.png")} /></p>
    <p>{`Publish the changes and you should start receiving Smilee events in Google Analytics 4`}</p>
    <h2 {...{
      "id": "push-chat-open-when-user-is-thinking-about-exiting-the-page"
    }}>{`Push chat open when user is thinking about exiting the page`}</h2>
    <p>{`Coming soon`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      